import React, { useState, useEffect} from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import * as Product from './libraries/Product.js';
import * as Cart from './libraries/Cart.js';

const FLI = () => {
  const [loading,setLoading] = useState(false);

  useEffect(()=>{
   
    // callback function to call when event triggers
    const FLIATCAdding = () => {
      console.log("Adding");
      setLoading(true);
    };
    window.addEventListener('__FLI_ATC_Adding', FLIATCAdding, false);


    const FLIATCFinished = () => {
      //console.log("Adding");
      setLoading(false);
    };
    window.addEventListener('__FLI_ATC_Finished', FLIATCFinished, false);

    // Remove the event listener when component unmounts
    return () => {
      window.removeEventListener('__FLI_ATC_Adding', FLIATCAdding);
      window.removeEventListener('__FLI_ATC_Finished', FLIATCFinished);
    }

  },[]);

  useEffect(()=>{
   
    // callback function to call when event triggers
    const onPageLoad = () => {
      //console.log('page loaded');

      Product.getFLIProducts(function(result){

        //PDP init
        if( result?.data?.products.edges.length>0 ){
          //console.log(result);
          //console.log('FLI Eligible');
          Product.init(result);

          window.dispatchEvent( new CustomEvent("__FLI_Loaded", {detail: result}) );

          //Listen:
          window.addEventListener('__ATC', function(e){
            //console.log(e.detail);
            Cart.ATC(e.detail.pid,e.detail.vid);
          }, false);
        }

      });
      // do something else
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    }
    else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }

  },[]);

  return (
    <div className="ATC">
      {loading && (
        <div className="loading_covert_container">
          <PuffLoader
            size={200}
            color={"#b46a55"}
          />
        </div>
      )}
    </div>
  );
}

export default FLI;
