import React, { useEffect, useState } from 'react';
import CartPageItem from './components/CartPageItem.js';
import PuffLoader from "react-spinners/PuffLoader";
import * as Cart from './libraries/Cart.js';
import './CartPage.css';

const CartPage = () => {
  const [items,setItems] = useState([]);
  const [freeItems,setFreeItems] = useState([]);
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
   
    // callback function to call when event triggers
    const FLICartLoaded = () => {
      console.log("fli cart loaded");

      //Seperate free and non-free items
      let temp_items = [];
      let temp_free_items = [];
      for(let key in window.__config.cart.items ){
        let item = window.__config.cart.items[key];
        if( Cart.isFreeLineItem(item) ){
          temp_free_items.push(item);
        }
        else temp_items.push(item);
      }

      setItems(temp_items);
      setFreeItems(temp_free_items);
      setLoading(false);

      //setItems(window.__config.cart.items);
      //Cart.load();
    };


    window.addEventListener('__FLI_Cart_Loaded', FLICartLoaded, false);
    // Remove the event listener when component unmounts
    return () => window.removeEventListener('__FLI_Cart_Loaded', FLICartLoaded);

  },[]);


  useEffect(()=>{
   
    // callback function to call when event triggers
    const FLILoaded = () => {
      //console.log("fli loaded");
      Cart.load();
    };


    if ( window.__config?.FLI ) {
      FLILoaded();
    }
    else {
      window.addEventListener('__FLI_Loaded', FLILoaded, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('__FLI_Loaded', FLILoaded);
    }

  },[]);

  return (
    <div className="content cartPage">
      { items.length>0 && items.map((data, i) => 
        <CartPageItem key={i+data.key+data.quantity}
          data={data}
          is_free={false}
        />
      )}
      { freeItems.length>0 && freeItems.map((data, i) => 
        <CartPageItem key={i+data.key+data.quantity}
          data={data}
          is_free={true}
        />
      )}
      
      {loading && (
        <div className="loading_covert_container">
          <PuffLoader
            size={200}
            color={"#b46a55"}
          />
        </div>
      )}

      
    </div>
  );
}

export default CartPage;
