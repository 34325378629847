//import * as Product from './Product.js';

export const ATC = (pid,vid)=>{
	let items = [];

	items.push({
    quantity: 1,
    id: vid+"",
  });
	//console.log(pid);
	//console.log((window.__config.FLI?.pid??null)!==null);
	if( (window.__config.FLI?.pid??null)!==null ){
		
		return ATCRaw(items);
	}

	//console.log(pid);
	let fli = window.__config.FLI[pid];
	//console.log(window.__config.FLI[pid]);

	for( let key in fli ){
    items.push({
      quantity: 1,
      id: fli[key]
    });
  }

  ATCRaw(items);

}

const ATCRaw = (items,callback=null)=>{
  window.dispatchEvent( new CustomEvent("__FLI_ATC_Adding", {detail: null}) );

  if( window.__config.adding ) return;
  
  window.__config.adding = true;

	const data = {items: items};
	fetch('/cart/add.js', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
    .then(
      (result) => {
        window.__config.adding = false;

        //that._addFinish();
        if( callback===null ){
        	window.dispatchEvent( new CustomEvent("__FLI_ATC_Finished", {detail: result}) );
        	console.log("Added");
        }
        else callback(result);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
      }
  );
}


export const load = ()=>{
	if( window.__config.env === "local" ){
		window.__config.cart = window.cart;
    return cartLoaded();
	}
	else{
		fetch('/cart.js', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => res.json())
    .then(
      (result) => {
        window.__config.cart = result;
        return cartLoaded();
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
      }
    );
	}
}

const cartLoaded = ()=>{
  for(let key in window.__config.cart.items ){
    let item = window.__config.cart.items[key];
    if( isFreeLineItem(item) ){
      //console.log(item);
      let main_product = getMainProductFromFreeLineItemItem(item);
      if( !isCartContainsCertainProduct(main_product.id.replace("gid://shopify/Product/","")) ){
        //FLI need to remove
        return change(item.key,0);
        //console.log( main_product );
      }
    }
    
  }
  window.dispatchEvent( new CustomEvent("__FLI_Cart_Loaded", {detail: null}) );
};

const isCartContainsCertainProduct = (pid)=>{
  //console.log(pid);
  for(let key in window.__config.cart.items ){
    let item = window.__config.cart.items[key];
    //console.log(item);
    if( item.product_id+""===pid+"" ){
      //console.log(item);
      return true;
    }
  }
  return false;
}

export const getFreeLineItemsByMainItem=(item)=>{
  if( typeof window.__config.FLI[item.product_id] !== "undefined" ){
    return window.__config.FLI[item.product_id];
  }
  else return [];
}

export const isFreeLineItem = (item)=>{
  if( window.__config.FLI_variants.includes(item.variant_id+"") ) return true;
  else return false;
}

export const isFreeLineMainItem = (item)=>{
  if( typeof window.__config.FLI[item.product_id] !== "undefined" ) return true;
  else return false;
}

export const getMainProductFromFreeLineItemItem = (item)=>{
  if( typeof window.__config.variants_object[item.variant_id+""] !== "undefined" ){
    return window.__config.variants_object[item.variant_id+""].main_product;
  }
  //if( window.__config.FLI_variants.includes(item.variant_id+"") ) return true;
  //else return false;
}

const getCartItemByKey = (key_item)=>{
  for( let key in window.__config.cart.items ){
    let item = window.__config.cart.items[key];
    if( item.key===key_item ){
      return item;
    }
  }
  return null;
}

const getCartItemByVariantID = (vid)=>{
  for( let key in window.__config.cart.items ){
    let item = window.__config.cart.items[key];
    if( item.variant_id+""===vid ){
      return item;
    }
  }
  return null;
}

export const change = (key,quantity)=>{
	let post_data = {};
  post_data["updates"] = {};
  post_data["updates"][key] = quantity;

  //Adjust free line items quantity as well
  let item_main = getCartItemByKey(key);
  if( !isFreeLineItem(item_main) && isFreeLineMainItem(item_main) ){
    let free_line_items_variants = getFreeLineItemsByMainItem(item_main);
    //console.log(free_line_items_variants);
    for( let key_variant in free_line_items_variants ){
      let free_line_item_variant_id = free_line_items_variants[key_variant];
      let free_line_item_cart_item = getCartItemByVariantID(free_line_item_variant_id);
      //console.log(free_line_item_cart_item);
      if( free_line_item_cart_item ){
        post_data["updates"][free_line_item_cart_item.key] = quantity;
      }
    }
  }
  

  fetch('/cart/update.js', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(post_data)
  }).then(res => res.json())
    .then((result) => {
      load();
    },
    // Note: it's important to handle errors here
    // instead of a catch() block so that we don't swallow
    // exceptions from actual bugs in components.
    (error) => {
      //load();
    }
  );
}