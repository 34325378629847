import React, { useEffect, useState } from 'react';
import * as Product from './libraries/Product.js';

const Saved = () => {
  const [total,setTotal] = useState(0);

  useEffect(()=>{
    // callback function to call when event triggers
    //const FLICartLoaded = () => {
    const FLICartLoaded = async () => {
      //console.log("fli cart loaded");

      let original_total = 0;
      for(let key in window.__config.cart.items){
        let item = window.__config.cart.items[key];
        let variant = await Product.getVariantByID(item.variant_id);
        original_total += variant.compareAtPrice.amount * item.quantity;
        //console.log(variant.compareAtPrice.amount * item.quantity);
      }

      setTotal( original_total*100-window.__config.cart.total_price );
    };


    window.addEventListener('__FLI_Cart_Loaded', FLICartLoaded, false);
    // Remove the event listener when component unmounts
    return () => window.removeEventListener('__FLI_Cart_Loaded', FLICartLoaded);

  },[]);


  return (
    <>{window.Shopify.formatMoney(total)}</>
  );
}

export default Saved;
