export const getFLIProducts = (callback=null) => {
  var query_fragment = `
    fragment ProductsFields on Product {
      id
      handle
      title
      tags
      images(first: 16) {
        edges {
          node {
            originalSrc
            id
            altText
          }
        }
      }
      options(first: 8) {
        id
        name
        values
      }
      descriptionHtml
      priceRange {
        minVariantPrice {
          amount
        }
        maxVariantPrice {
          amount
        }
      }
      sellingPlanGroups(first: 8){
        edges{
          node{
            name
            options{
              name
              values
            }
            sellingPlans(first: 8){
              edges{
                node{
                  id
                  name
                  description
                  recurringDeliveries
                  options {
                    name
                    value
                  }
                  checkoutCharge{
                    value{
                      ... on SellingPlanCheckoutChargePercentageValue{
                        percentage
                      }
                    }
                  }
                  priceAdjustments {
                    orderCount
                    adjustmentValue {
                      __typename
                      ... on SellingPlanPercentagePriceAdjustment {
                        adjustmentPercentage
                      }
                      ... on SellingPlanFixedAmountPriceAdjustment {
                        adjustmentAmount {
                          amount
                          currencyCode
                        }
                      }
                      ... on SellingPlanFixedPriceAdjustment {
                        price {
                          amount
                          currencyCode
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      variants(first: 16){
        edges{
          node{
            id
            availableForSale
            quantityAvailable
            sellingPlanAllocations(first:8){
              edges{
                node{
                  priceAdjustments{
                    price{
                      amount
                    }
                  }
                }
              }
              
            }
            price{
              amount
              currencyCode
            }
            compareAtPrice{
              amount
              currencyCode
            }
            title
            image{
              id
              url
              altText
            }
            selectedOptions{
              name
              value
            }
          }
        }
      }
    }
  `;

  var query_product = `
    products(first:20, query: "tag:free-line-item") {
      edges{
        node{
          ...ProductsFields
          free_line_items: metafield(namespace: "fli", key: "free_line_item_free"){
            value
            references(first: 16) {
              edges{
                node{
                  ... on ProductVariant {
                    id
                    price{
                      amount
                      currencyCode
                    }
                    compareAtPrice{
                      amount
                      currencyCode
                    }
                    image{
                      id
                      url(transform: { maxWidth: 300 })
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  var query,variables;
  query = `{`+query_product+`}`;
  variables = {
    
  };

  query += query_fragment;
  

  fetch(window.__config.api_url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': window.__config.api_access_token,
    },
    body: JSON.stringify({ query: query, variables: variables })
  }).then(res => res.json())
    .then((result) => {
      console.log(result);
      if( callback!==null ) callback(result);
    },
    // Note: it's important to handle errors here
    // instead of a catch() block so that we don't swallow
    // exceptions from actual bugs in components.
    (error) => {
      console.log(error);
      //MainStore.ItemChanged();
    }
  );
}

export const getVariantByID = async(id)=>{
  if( typeof window.__config.variants[id] !== "undefined" ) return window.__config.variants[id];

  //console.log("get");
  const query = `
    query getVariantById($variantId: ID!) {
      node(id: $variantId) {
        ... on ProductVariant {
          id
          title
          sku
          availableForSale
          compareAtPrice {
            amount
            currencyCode
          }
        }
      }
    }
  `;

  const variables = {
    variantId: "gid://shopify/ProductVariant/"+id
  };


  try {
    const response = await fetch(window.__config.api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': window.__config.api_access_token,
      },
      body: JSON.stringify({
        query,
        variables
      })
    });

    const data = await response.json();
    //console.log(data);
    let variant = data.data.node;

    window.__config.variants[id] = variant;
    
    return variant;
    //return data;
  } catch (error) {
    console.error('Error fetching variant:', error);
  }
}

export const init=(result)=>{
  window.__config.PDP_raw = result;

  //FLI
  window.__config.FLI = {};
  window.__config.FLI_variants = [];
  if( !window.__config?.variants_object ) window.__config.variants_object = {};
  //let ids_raw = JSON.parse(result.data.product.free_line_items.value);
  for(let key in result.data.products.edges ){
    let product = result.data.products.edges[key].node;
    if( product.free_line_items===null ) continue;
    let product_id = product.id.replace("gid://shopify/Product/","");
    let ids_raw = JSON.parse(product.free_line_items.value);

    window.__config.FLI[product_id] = [];
    for(let key_id in ids_raw ){
      let id = ids_raw[key_id].replace("gid://shopify/ProductVariant/","");
      window.__config.FLI[product_id].push(id);
      window.__config.FLI_variants.push(id);
      //window.__config.variants_object[id] = ids_raw[key_id];
    }

    for(let key_refrence in product.free_line_items.references.edges){
      let obj = product.free_line_items.references.edges[key_refrence].node;
      let id = obj.id.replace("gid://shopify/ProductVariant/","");
      window.__config.variants_object[id] = {variant:obj,main_product:product};
    }
  }
}

