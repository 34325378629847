import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import FLI from './FLI';
import CartPage from './CartPage';
import Total from './Total';
import Saved from './Saved';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('FLI'));
root.render(
  <React.StrictMode>
    <FLI />
  </React.StrictMode>
);

const rootCartPage = ReactDOM.createRoot(document.getElementById('CartPage'));
rootCartPage.render(
  <React.StrictMode>
    <CartPage />
  </React.StrictMode>
);

const rootTotal = ReactDOM.createRoot(document.getElementById('FLITotal'));
rootTotal.render(
  <React.StrictMode>
    <Total />
  </React.StrictMode>
);

const rootSaved = ReactDOM.createRoot(document.getElementById('FLISaved'));
rootSaved.render(
  <React.StrictMode>
    <Saved />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
