import React, { useState, useEffect } from 'react';
import * as Cart from '../libraries/Cart.js';
import * as Product from '../libraries/Product.js';
import QuantityInput from './QuantityInput';


const CartPageItem = (props) => {
  const isFreeLineItem = props.is_free;
  const item_key = props.data.key;
  const item = props.data;
  const [quantity,setQuantity] = useState(props.data.quantity);
  const [loading,setLoading] = useState(false);
  const [variant,setVariant] = useState(null);

  const quantityChanged = (value) =>{
    console.log("changed:"+value);
    setQuantity(value);
    setLoading(false);
    Cart.change(item_key,value);
  };

  const quantityBlur = (value) =>{
    console.log("blur:"+value);
    Cart.change(item_key,value);
  };

  const quantityRemove = () =>{
    console.log("removed:");
    setQuantity(0);
    setLoading(false);
    Cart.change(item_key,0);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setVariant(await Product.getVariantByID(item.variant_id));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the async function

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="grid grid--table cart-item">
        <div className="grid__item small--one-half medium-up--one-quarter medium-up--text-right" data-label="Product">
          <a href={item.url}>
            <img className="cart-thumbnail lazyloaded" alt={item.title} src={item.image} />
          </a>
        </div>
        <div className="grid__item small--one-half medium-up--one-quarter">
          <a href={item.url} className="product-title-link">{item.title}</a>
          <div className="variant-title">{item.variant_title}</div>
          
          <div className="product-form-item-quantity">
            {(() => {
              if (!isFreeLineItem) {
                return (
                  <QuantityInput
                    quantity={quantity} 
                    loading={loading}
                    quantityChanged     = {quantityChanged}
                    quantityBlur        = {quantityBlur}
                    quantityRemove      = {quantityRemove}
                  />
                )
              }
              else{
                return (
                  <input type="number" disabled min="1" aria-label="Item quantity" className="quantity-selector" defaultValue={quantity}/>
                )
              }
            })()}
          </div>
          {item.properties && Object.keys(item.properties).map((key, index) => 
            <div key={index}>
              <div>{key}:{item.properties[key]}</div>
            </div>
          )}

          {(() => {
            if (!isFreeLineItem) {
              return (
                <div className="js-qty-adjust js-qty-adjust-remove" onClick={e=>quantityRemove()}>
                  <small>Remove</small>
                </div>
              )
            }
          })()}
          
        </div>

        <div className="grid__item small--one-whole medium-up--one-quarter text-center product-prices" data-label="Price">
          {(() => {
            if ( variant && variant?.compareAtPrice?.amount ) {
              return (
                <span className="strike-through">
                  <span className="visually-hidden">Original price</span>
                  <span className="compare-price">${variant?.compareAtPrice?.amount}</span>
                </span>
              )
            }
          })()}
          <span className="current-price">
            {window.Shopify.formatMoney(item.price)}
          </span>
        </div>

        <div className="grid__item small--hide medium-up--one-quarter text-center product-prices" data-label="Total">
          <span className="product-total">{window.Shopify.formatMoney(item.price*item.quantity)}</span>
        </div>
      </div>
      {/*<hr className="line-divider medium-up--hide"/>*/}
    </>
  );
}

export default CartPageItem;
