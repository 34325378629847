import React, {useState, useEffect} from 'react';

const QuantityInput = (props)=> {
  const original_quantity = props.quantity;
  const [quantity,setQuantity] = useState(props.quantity);
  const step = props?.step??1;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(props.loading);
  }, [props]);
 
  useEffect(() => {
    const debounceDelay = window.__config.interaction_debounce_delay_ms;

    // Use setTimeout to run the function after the debounce delay
    const debounceTimeout = setTimeout(() => {

      if( original_quantity!==quantity ){
        //console.log(quantity);
        setLoading(true);
        if( quantity===0 ){
          //Carts.productQuantityUpdate(item,quantity);
          props.quantityRemove();
        }
        else{
          //console.log(quantity);

          props.quantityChanged(quantity);
        }
        
      }
    }, debounceDelay);

    return () => clearTimeout(debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[quantity]);

  /*  
  const onChanged = (e,quantity)=>{
    changed(e.target.value);
  };
  */

  const changed = (quantity)=>{
    //if( quantity==="" ) quantity = 0;
    setQuantity(quantity);
  };

  const blur = () =>{
    setQuantity(quantity);
  };

  const decrease = () =>{
    //if( quantity==="" ) quantity = 0;
    let new_quantity = parseInt(quantity);
    //console.log(new_quantity);
    if( Number.isNaN(new_quantity) ) new_quantity = 0;
    new_quantity-=step;
    if( new_quantity<0 ) new_quantity = 0;

    setQuantity(new_quantity);
  };

  const increase = () =>{
    let new_quantity = parseInt(quantity);
    if( Number.isNaN(new_quantity) ) new_quantity = 0;
    new_quantity+=step;
    setQuantity(new_quantity);
  };

  return (
    <>
      <button type="button" onClick={e=>decrease()} className="js-qty-adjust js-qty-adjust-minus" disabled={loading}>
        <span aria-hidden="true">−</span>
        <span className="visually-hidden"></span>
      </button>
      <input type="text" name="quantity" inputMode="numeric" value={quantity} onChange={e=>changed(e.target.value)} onBlur={e=>blur()} min="1" aria-label="Item quantity" className="quantity-selector" disabled={loading}/>
      <button type="button" onClick={e=>increase()} className="js-qty-adjust js-qty-adjust-plus" disabled={loading}>
        <span aria-hidden="true">+</span>
        <span className="visually-hidden"></span>
      </button>
    </>
  )
  
}
export default QuantityInput;