import React, { useEffect, useState } from 'react';
//import * as Product from './libraries/Product.js';
//import CartPageItem from './components/CartPageItem.js';
//import * as Cart from './libraries/Cart.js';
//import './CartPage.css';

const Total = () => {
  const [total,setTotal] = useState(0);

  useEffect(()=>{
    // callback function to call when event triggers
    const FLICartLoaded = () => {
      //console.log("fli cart loaded");

      setTotal(window.__config.cart.total_price);
    };


    window.addEventListener('__FLI_Cart_Loaded', FLICartLoaded, false);
    // Remove the event listener when component unmounts
    return () => window.removeEventListener('__FLI_Cart_Loaded', FLICartLoaded);

  },[]);




  return (
    <>{window.Shopify.formatMoney(total)}</>
  );
}

export default Total;
